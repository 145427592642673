import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/Seo';
// import DOMPurify from 'isomorphic-dompurify';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '@components/Layout';
import Typography from '@material-ui/core/Typography';
// import SideBar from '@components/SideBar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tag from '@components/Tag';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    flex: '1 0 auto',
    textAlign: 'left',
    display: 'block',
    '& h2': {
      fontFamily: theme.typography.h2.fontFamily,
      fontWeight: theme.typography.h2.fontWeight,
      fontSize: theme.typography.h2.fontSize,
      lineHeight: theme.typography.h2.lineHeight,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h2.sm.fontSize,
      },
    },
    '& li': {
      fontFamily: theme.typography.p.fontFamily,
      fontWeight: theme.typography.p.fontWeight,
      fontSize: theme.typography.p.fontSize,
      lineHeight: theme.typography.p.lineHeight,
    },
    '& p': {
      textAlign: 'justify',
      fontFamily: theme.typography.p.fontFamily,
      fontWeight: theme.typography.p.fontWeight,
      fontSize: theme.typography.p.fontSize,
      lineHeight: theme.typography.p.lineHeight,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.p.sm.fontSize,
      },
    },
  },
  title: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    marginBottom: `${theme.spacing(5)}px`,
    marginTop: `${theme.spacing(5)}px`,
    '& h1': {
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h1.sm.fontSize,
      },
    },
  },
  body: {
    textAlign: 'left',
    display: 'flex',
    margin: 'auto',
    flexWrap: 'wrap',
    '& a': {
      color: '#b1b1ff',
      textDecoration: 'underline',
    },
    padding: `0px ${theme.spacing(15)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `0px ${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `0px ${theme.spacing(10)}px`,
    },
  },
  sideBar: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const query = graphql`
query($slug: String) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    frontmatter {
      title
      date
      tags
    }
    html
  }
  site {
    siteMetadata {
      title,
      description,
      keywords,
      author,
      siteUrl,
      image,
      twitterUsername,
    }
  }
}
`;
const Blog = ({ data, location }) => {
  const classes = useStyles();
  // const cleanHtml = DOMPurify.sanitize(props.data.markdownRemark.html);
  const { href } = location;
  const cleanHtml = data.markdownRemark.html;
  const { title: titleFm, tags } = data.markdownRemark.frontmatter;
  const {
    description, author, image, twitterUsernname,
  } = data.site.siteMetadata;

  return (
    <Layout>
      <SEO
        title={titleFm}
        description={description}
        keywords={tags}
        author={author}
        url={href}
        image={image}
        type="website"
        locale="en_US"
        twitterUsernname={twitterUsernname}
      />
      <Box className={classes.root}>
        <Grid className={classes.title} item xs={12} sm={12} lg={8}>
          <Typography variant="h1">
            {titleFm}
          </Typography>
          <Box my={3} display="flex" flexWrap="wrap" justifyContent="center">
            {tags.split(',').map((tag) => <Tag name={tag} />)}
          </Box>
          {/* <Typography variant="subtitle1">
            {frontmatter.tags}
          </Typography> */}
        </Grid>
        <Box className={classes.body}>
          <Grid item xs={12} sm={12} lg={9} style={{ margin: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
          </Grid>
          {/* <Grid className={classes.sideBar} item xs={12} sm={12} lg={3}>
            <SideBar />
          </Grid> */}
        </Box>
      </Box>
    </Layout>
  );
};

Blog.propTypes = {

  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        tags: PropTypes.string.isRequired,
      }),
      html: PropTypes.string.isRequired,
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        keywords: PropTypes.string.isRequired,
        twitterUsernname: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }).isRequired,
};

export default Blog;
